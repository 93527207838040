import React from "react";
import ReactStrip from "../components/ReactStrip";

export default function Subscription() {
	return (
		<div>
			<br />
			<br />
			<ReactStrip />
			{/* <Test /> */}
		</div>
	);
}
